@import "~@blueprintjs/core/lib/scss/variables";

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: white;
}

.header-left {
  flex: 1 1;
}

.header-right {
  flex: 0 1;
  min-width: fit-content;
}

.navigation {
  box-shadow: none;
}
