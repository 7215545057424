.raw {
  white-space: pre-wrap;
  text-align: left;
}
.markdown {
  line-height: normal;
  text-align: left;
  table {
    width: 100%;
    max-width: 100%;
    padding: 6px;
    margin-bottom: 20px;
    border-spacing: 0;
    border-collapse: collapse;
  }
  table > tbody > tr:nth-child(odd) {
    background-color: rgba(92, 112, 128, 0.3);
  }
  table,
  th {
    border-bottom: 2px solid gray;
    padding: 6px;
  }
  table,
  td {
    border-bottom: 1px solid gray;
    padding: 6px;
  }
  .hint {
    background-color: rgba(92, 112, 128, 0.3);
    border: 1px solid gray;
    padding: 6px;
    margin: 0px;
    margin-bottom: 20px;
  }
  .tip {
    color: yellow;
  }
  .warn {
    color: orange;
  }
  .error {
    color: red;
  }
  img {
    image-rendering: auto;
    max-width: 100%;
  }
  .super {
    display: inline;
    vertical-align: super;
    font-size: x-small;
  }
  .suber {
    display: inline;
    vertical-align: sub;
    font-size: x-small;
  }
  .anchor-nav-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }
}
