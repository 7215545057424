@import "~@blueprintjs/core/lib/scss/variables";

.message {
    & > :first-child {
        border: 2px solid $pt-intent-primary;
        & > :first-child,
        :last-child {
            color: $pt-intent-primary;
        }
    }
}
