@import "~@blueprintjs/core/lib/scss/variables";
.single-input {
    line-height: 19px;
    margin-bottom: 0;
    input {
        height: 2rem;
    }
    label {
        font-weight: 600;
        font-size: 14px;
    }
    .helperText {
        font-size: small;
    }
    .red {
        color: $pt-intent-danger;
    }
    .grey {
        color: grey;
    }
}
