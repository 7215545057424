.footer-content {
  display: flex;
  flex-direction: column;
}
.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1;
}
.footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 1;
}
