@mixin break() {
  flex-basis: 100%;
  height: 0;
}

@mixin sp($t) {
  width: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 0rem;
  column-gap: 3rem;
  > #{$t} {
    flex: 1 0 auto;
    input {
      width: 100%;
    }
  }
  > #{$t}:last-of-type {
    flex: 0 0 auto;
    text-align: right;
  }
  > .label {
    flex-basis: 100%;
    min-width: 20em;
  }
  > .select {
    min-width: 10em;
    & span {
      width: max-content;
    }
  }
  > .error {
    min-width: 16em;
  }
  > .break {
    @include break();
  }
  > .unit {
    width: 100%;
    min-width: 20em;
  }
  > .configuration {
    width: 100%;
    min-width: 20em;
  }
  > .setpoint {
    width: 100%;
    min-width: 20em;
  }
  > .breakpoint {
    flex: 0 0 auto;
    min-width: 4em;
  }
  > .schedule {
    min-width: 20em;
  }
  > .occupancy {
    flex: 1 0 auto;
    min-width: 20em;
  }
  > .unoccupied {
    flex: 0 0 auto;
    min-width: 4em;
  }
  > .holiday {
    width: 100%;
    min-width: 20em;
  }
  > .enabled {
    flex: 0 0 auto;
    min-width: 4em;
  }
}

div[hidden] {
  display: none !important;
}

.global-loading {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 45vh;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: #ffffff55;
}
